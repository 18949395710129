import React from "react"
import BlogRoll from "../components/BlogRoll"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

const Scroll = loadable(() => import("../components/scroll"))
const Footer = loadable(() => import("../components/footer3"))

export default class BlogLatestIndexPage extends React.Component {
  render() {
    return (
      <React.Fragment>
         <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Latest Content </title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta name="description" content="Learn well-designed content in a guided order to master computer science." />
        </Helmet>
        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
        <div className="container bg-light-white mx-auto px-6 lg:px-8 xl:px-10">
          <h1 className="tracking-wider text-xl lg:text-2xl 2xl:text-3xl pt-8 text-center text-gray-900">
            <span className="">Latest Content on EnjoyAlgorithms</span>
          </h1>

          <section>
            <div className="content mt-6">
              <BlogRoll />
            </div>
          </section>
          <Scroll showBelow={250} />
          <Footer />
        </div>
        </div>
      </React.Fragment>
    )
  }
}
